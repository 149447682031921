// eslint-disable-next-line no-redeclare,no-unused-vars
function ajax(param, trys) {
  // view from cache
  if (
    param.object &&
    param.type &&
    !param.updating &&
    !param.post &&
    !param.delete &&
    !param.background &&
    !param.picker
  ) {
    var ident = [param.object, param.objectSub, param.id]
      .filter(Boolean)
      .join('_');
    if (viewStack[ident] && $.contains(document.body, viewStack[ident][0])) {
      viewStack[ident].show();
      return;
    }
  }

  // func
  if (param.type == 'calendar') return overview(param);
  else if (param.type && inArray(param.type, ['table', 'calendar', 'chart'])) {
    param.func = 'overview';
    if (param.send) {
      delete param.send.table;
      delete param.send.calendar;
    }
    setObjectValue(param, ['send', param.type], true);
  } else if (param.type == 'detail') param.func = 'detail';
  if (param.picker) setObjectValue(param, ['send', 'table'], true);

  // default view saver
  if (param.func == 'overview' && !param.picker && !param.modal) {
    var views = getParamSettings(
      {object: param.object, objectSub: param.objectSub},
      'view'
    );
    if (views) {
      var i = arraySearchAssoc(views, 'default', 1);
      if (
        i !== false &&
        (views[i].owner == user.id || parseInt(views[i].defaultAll))
      ) {
        param.defaultView = i;
        param.tableFields = views[i].tableFields;
        param.sort = views[i].sort;
        if (views[i].sort)
          setObjectValue(param, ['send', 'sort'], views[i].sort);
        if (views[i].filter)
          setObjectValue(param, ['send', 'where'], views[i].filter);
        if (views[i].tableFields)
          setObjectValue(param, ['send', 'tableFields'], views[i].tableFields);
      }
    }

    // default table fields
    if (!isset(param.defaultView)) {
      param.identGroup = [
        param.object,
        param.objectSub,
        param.refObj,
        param.refObjSub,
        param.refKey,
      ]
        .filter(Boolean)
        .join('_');
      var localTableFields = localStorage.getItem(
        param.identGroup + '_tableFields'
      );
      if (localTableFields)
        setObjectValue(
          param,
          ['send', 'tableFields'],
          localTableFields.split(',')
        );
    }
  }

  // get
  var get = {};
  if (param.get) Object.assign(get, param.get);
  if (windowId) get.window = windowId;
  if (debug) get.debug = debug;

  // request
  if (!param.background) loading(true, param.waiting);
  var url = endpoint;
  if (param.object) url += param.object;
  if (param.objectSub && isset(param.objectSub)) url += '+' + param.objectSub;
  if (param.id) url += ',' + param.id;
  url += '?' + $.param(get);
  var pm = {url: url, headers: {}, xhrFields: {withCredentials: true}};
  // if (windowId) pm.headers['Window'] = windowId;
  // if (demo) pm.headers['Demo'] = demo;
  if (param.noTimeout) pm.timeout = 0;
  // if (param.object != 'settings' && !param.noTimeout) pm.timeout = 60000;
  if (param.send && !isEmptyObject(param.send)) pm.data = param.send;
  if (param.post) pm.method = 'POST';
  if (param.delete) pm.method = 'DELETE';

  // var uri = url; if (pm.data) uri += '?'+$.param(pm.data);
  // var check = checksum(pm);
  // if (running[check]) return;
  // no same request
  // if (param.callback) running[check].callback2 = param.callback;
  // return;
  // } // no same request
  // else running[check] = param;
  // else if (!param.updating && !param.callback && !param.send) return;

  // check for double request
  var check = checksum(pm);
  if (running[check]) return;
  running[check] = param;

  // request
  var request = $.ajax(pm);
  request.done(function (xhr) {
    if (running[check]) delete running[check];
    ajaxResponse(param, xhr);
    if (!param.background) loading(false, param.waiting);
  });
  request.fail(function (evt) {
    if (running[check]) delete running[check];
    if (!trys) trys = 1;
    trys++;
    if (inArray(evt.status, [200, 403, 500]) || trys > 5) {
      if (param.callbackFailed) param.callbackFailed();
      else showInfo(info.error, 'error');
      if (!param.background) loading(false, param.waiting);
    } else if (trys <= 5) ajax(param, trys);
  });
  setTimeout(function () {
    if (running[check]) delete running[check];
  }, 3000);
}
